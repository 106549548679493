import { AnchorLink } from "gatsby-plugin-anchor-links"
import React, { ReactElement, useLayoutEffect, useRef, useState } from "react"
import { animated, useSpring } from "react-spring"

const Menu = (): ReactElement => {
  const btn = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)
  const radius = isOpen ? 200 : 0
  const spring = useSpring({
    clipPath: `circle(${radius}% at ${left}px ${top}px)`,
    pointerEvents: isOpen ? "auto" : "none",
    // WebkitClipPath: `circle(${radius}% at ${left}px ${top}px)`,
    opacity: isOpen ? 1 : 0,
    config: {
      mass: 1,
      tension: 325,
      friction: 40,
    },
  })

  useLayoutEffect(() => {
    if (btn && btn.current) {
      const { current } = btn
      setTop(current.offsetTop + current.offsetHeight / 2)
      setLeft(current.offsetLeft + current.offsetWidth / 2)
    }
  }, [btn])

  return (
    <>
      <button
        ref={btn}
        type="button"
        id="hamburger"
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg
          id="hamburger__icon"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 140 140"
          className="fill-current text-white"
        >
          <g
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          >
            <path
              d="M13.125 105.017h113.75M13.125 70.017h113.75M13.125 35.017h113.75"
              strokeWidth="8.749995"
            />
          </g>
        </svg>
      </button>
      <animated.div id="menu" style={spring}>
        <div>
          <ul>
            <li className="menu-item">
              <AnchorLink
                to="/#about"
                title="About"
                onAnchorLinkClick={() => setIsOpen(false)}
              >
                About
              </AnchorLink>
            </li>
            <li className="menu-item">
              <AnchorLink
                to="/#work"
                title="Work"
                onAnchorLinkClick={() => setIsOpen(false)}
              >
                Work
              </AnchorLink>
            </li>
            <li className="mt-4">
              <a
                href="mailto:justinpmartin@gmail.com"
                className="block text-red font-utopia text-xl"
              >
                justinpmartin@gmail.com
              </a>
            </li>
            <li>
              <a
                href="tel:1-403-701-4255"
                className="block whitespace-nowrap text-red font-utopia text-xl"
              >
                1 (403) 701-4255
              </a>
            </li>
          </ul>
        </div>
      </animated.div>
    </>
  )
}
export default Menu
