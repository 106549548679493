import React, { ReactElement } from "react"
import Menu from "./menu"

interface Props {
  children: JSX.Element | JSX.Element[]
}

export default function Layout({ children }: Props): ReactElement {
  return (
    <div className="relative">
      {children}
      <Menu />
    </div>
  )
}
